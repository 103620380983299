@keyframes slideIn {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-600px);
  }
}
@-moz-keyframes slideIn {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-600px);
  }
}
@-webkit-keyframes slideIn {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-600px);
  }
}
@keyframes slideIn {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-600px);
  }
}
@-moz-keyframes slideIn {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-600px);
  }
}
@-webkit-keyframes slideIn {
  0% {
    transform: translateX(-600px);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes slideOut {
  0% {
    transform: translateX(600px);
  }
  100% {
    transform: translateX(0);
  }
}
@-moz-keyframes slideOut {
  0% {
    transform: translateX(600px);
  }
  100% {
    transform: translateX(0);
  }
}
@-webkit-keyframes slideOut {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(600px);
  }
}
@keyframes slideOut {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(600px);
  }
}
@-moz-keyframes slideOut {
  0% {
    transform: translateX(0);

  }
  100% {
    transform: translateX(600px);
  }
}
@-webkit-keyframes slideOut {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(600px);
  }
}


.slideInWrapper {
  overflow: hidden;
}

.dSlides {
  display: flex;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
}
.dSlides::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
.dSlides::-webkit-scrollbar-thumb {
  background: black;
  border-radius: 10px;
}
.dSlides::-webkit-scrollbar-track {
  background: transparent;
}
