.dSelectBoxWrapper input{
  opacity: 0 !important;
}

.dSelectBoxWrapper.standard div[class$="-control"],
.dSelectBoxWrapper.standard div[class*="-control "] {
  border: none;
  border-bottom: 1px solid hsl(0,0%,80%);
  border-radius: 0;
}
.dSelectBoxWrapper.standard div[class$="-indicatorContainer"],
.dSelectBoxWrapper.standard div[class*="-indicatorContainer "] {
  padding-top: 2px;
  padding-bottom: 2px;
}

.dSelectBoxWrapper.outline div[class$="-control"] {
  border-color: hsl(0,0%,80%) !important;
  border-radius: var(--round-1);
}
.dSelectBoxWrapper.outline div[class$="-MenuList"] {
  border-color: hsl(0,0%,80%) !important;
}
.dSelectBoxWrapper div[class$="-option"] {
  background-color: unset;
  color: black;
}

.dSelectBoxWrapper div[class$="-option"]:hover {
  background-color: var(--primary);
  color: var(--on-prime);
}

.dSelectBoxWrapper.outline div[class$="-control"]:hover,
.dSelectBoxWrapper.outline div[class*="-control"]:hover ,
.dSelectBoxWrapper.outline div[class$="-control"]:focus,
.dSelectBoxWrapper.outline div[class*="-control"]:focus,
.dSelectBoxWrapper.outline div[class$="-control"]:active,
.dSelectBoxWrapper.outline div[class*="-control"]:active {
  border-color: var(--primary) !important;
}

