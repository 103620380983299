:root {
  --primary: #d2ab67;
  --primary-light-40: #D2AB67FF;
  --primary-darken-10: #cb9e4f;
  --darkblue:#0e2947;
  --darkblue-darken-30:#0a1d32;
  --darkblue-lighten-30:#2773c7;
  --color-primary: var(--darkblue);
  --color-primary-lighen-55: #5f9cdf;
  --link:#0d2540;
  --link-darken-10:#0c213a;
  --white: #fff;

  --secondary: var(--darkblue);
  --secondary-light-20: #1c528e;
  --success: var(--primary);
  --danger: var(--primary);
  --info: var(--primary);
  --warning: var(--primary);
  --on-prime: #fff;
  --none: transparent;
  --light: var(--gray);
  --border: var(--border);
  --darkblue-lighten-15: #153e6a;
  --checkbox: var(--darkblue-lighten-15);

  --gray-100: rgb(248, 249, 250);
  --gray-200: rgb(233, 236, 239);
  --gray-300: rgb(222, 226, 230);
  --gray-400: rgb(206, 212, 218);
  --gray-500: rgb(173, 181, 189);
  --gray-600: rgb(108, 117, 125);
  --gray-700: rgb(73, 80, 87);
  --gray-800: rgb(52, 58, 64);
  --gray-900: rgb(33, 37, 41);
  --black: rgb(0, 0, 0);

  --round-1: 5px;
  --round-2: 15px;
  --round-50: 50px;

  /*Icons*/
  --icomoon-font-family: 'custom-icons';
  /*--icomoon-font-path: url('../fonts/font-icon');*/
  --icon-at: '\e923';
  --icon-calendar1: '\e924';
  --icon-mobile-phone: '\e925';
  --icon-Shape: '\e926';
  --icon-tag: '\e901';
  --icon-new-1: '\e910';
  --icon-new-2: '\e903';
  --icon-mark: '\e908';
  --icon-info: '\e921';
  --icon-refund: '\e922';
  --icon-close: '\e905';
  --icon-diamond: '\e907';
  --icon-calendar: '\e906';
  --icon-star: '\e90a';
  --icon-storage: '\e90b';
  --icon-support: '\e90c';
  --icon-user: '\e90d';
  --icon-www: '\e90e';
  --icon-minus: '\e90f';
  --icon-order: '\e911';
  --icon-password: '\e912';
  --icon-pencil: '\e913';
  --icon-plus-1: '\e914';
  --icon-plus-2: '\e915';
  --icon-plus-3: '\e916';
  --icon-product: '\e917';
  --icon-protest: '\e918';
  --icon-bill-1: '\e920';
  --icon-bill-2: '\e919';
  --icon-bin: '\e91a';
  --icon-gear: '\e91b';
  --icon-dashboard-1: '\e91c';
  --icon-dashboard-2: '\e91d';
  --icon-dashboard-3: '\e91e';
  --icon-dashboard-4: '\e91f';
  --icon-bookmark: '\e900';
  --icon-menu: '\e909';
  --icon-search: '\e904';
  --icon-check: '\e902';
  --icon-bell-o: '\f0a2';
  --icon-angle-left: '\f104';
  --icon-angle-right: '\f105';
  --icon-angle-down: '\f107';


  --border-width: 1px-;
  --input-btn-focus-width: 0.2rem;
  --component-active-bg: var(--primary-darken-10);
  --input-btn-focus-color: rgba(var(--component-active-bg, 0.25));
  --link-hover-color: #c79743;
  --component-active-color: var(--white);
  --input-btn-focus-box-shadow: 0 0 0 var(--input-btn-focus-width) var(--input-btn-focus-color);

  --pagination-padding-y: 0.5rem;
  --pagination-padding-x: 0.75rem;
  --pagination-padding-y-sm: 0.25rem;
  --pagination-padding-x-sm: 0.5rem;
  --pagination-padding-y-lg: 0.75rem;
  --pagination-padding-x-lg: 1.5rem;
  --pagination-line-height: 1.25;

  --pagination-color: var(--primary-darken-10);
  --pagination-bg: var(--white);
  --pagination-border-width: var(--border-width);
  --pagination-border-color: var(--gray-300);

  --pagination-focus-box-shadow: var(--input-btn-focus-box-shadow);
  --pagination-focus-outline: 0;

  --pagination-hover-color: var(--link-hover-color);
  --pagination-hover-bg: var(--gray-200);
  --pagination-hover-border-color: var(--gray-300);

  --pagination-active-color: var(--component-active-color);
  --pagination-active-bg: var(--component-active-bg);
  --pagination-active-border-color: var(--pagination-active-bg);

  --pagination-disabled-color: var(--gray-600);
  --pagination-disabled-bg: var(--white);
  --pagination-disabled-border-color: var(--gray-300);

  --content-margin: 4.5rem;

}
