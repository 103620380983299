.badge {
  border-radius: var(--round-1);
}
.badge-primary {
  background-color: var(--primary);
  color: var(--on-prime) !important;
}
.badge-secondary {
  background: var(--secondary);
}
.badge-danger {
  background: var(--danger);
  color: #212529;
}
