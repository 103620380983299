.footer {
  flex: 0 0 auto;
}
.footer p {
  text-align: center;
  margin: 10px 0 0 0;
}
.footer p a {
  text-decoration: none;
}
.copyright {
  bottom: 0;
  width: 100%;
  text-align: center;
  /*todo:*/
  color:#0E2947FF;
  padding: 0 25px;
  flex: 0 0 auto;
  z-index: 2;
}
.copyright a:hover {
  text-decoration: none;
}
