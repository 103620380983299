@font-face {
  font-family: custom-icons;
  src: url(../fonts/font-icon/custom-icons.eot?nxd9ng);
  src: url(../fonts/font-icon/custom-icons.eot?nxd9ng.eot?nxd9ng#iefix) format('embedded-opentype'), url(../fonts/font-icon/custom-icons.ttf?nxd9ng) format('truetype'), url(../fonts/font-icon/custom-icons.woff?nxd9ng) format('woff'), url(../fonts/font-icon/custom-icons.eot?nxd9ng.svg?nxd9ng#custom-icons) format('svg');
  font-weight: normal;
  font-style: normal;
}
[class^='icon-'], [class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: custom-icons !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-at:before {
  content: var(--icon-at);
  color: var(--silver);
}
.icon-calendar1:before {
  content: var(--icon-calendar1);
  color: var(--silver);
}
.icon-mobile-phone:before {
  content: var(--icon-mobile-phone);
  color: var(--silver);
}
.icon-Shape:before {
  content: var(--icon-Shape);
}
.icon-tag:before {
  content: var(--icon-tag);
  color: #7e86af;
}
.icon-new-1:before {
  content: var(--icon-new-1);
  color: #ffb300;
}
.icon-new-2:before {
  content: var(--icon-new-2);
  color: #f9f9fb;
}
.icon-mark:before {
  content: var(--icon-mark);
  color: #808091;
}
.icon-info:before {
  content: var(--icon-info);
  color: var(--primary);
}
.icon-refund:before {
  content: var(--icon-refund);
}
.icon-close:before {
  content: var(--icon-close);
}
.icon-diamond:before {
  content: var(--icon-diamond);
}
.icon-calendar:before {
  content: var(--icon-calendar);
  color: var(--silver);
}
.icon-star:before {
  content: var(--icon-star);
  color: #808091;
}
.icon-storage:before {
  content: var(--icon-storage);
  color: #808091;
}
.icon-support:before {
  content: var(--icon-support);
}
.icon-user:before {
  content: var(--icon-user);
}
.icon-www:before {
  content: var(--icon-www);
}
.icon-minus:before {
  content: var(--icon-minus);
  color: #808091;
}
.icon-order:before {
  content: var(--icon-order);
  color: var(--primary);
}
.icon-password:before {
  content: var(--icon-password);
  color: #b5b5be;
}
.icon-pencil:before {
  content: var(--icon-pencil);
  color: #dddfe9;
}
.icon-plus-1:before {
  content: var(--icon-plus-1);
  color: var(--silver);
}
.icon-plus-2:before {
  content: var(--icon-plus-2);
  color: #808091;
}
.icon-plus-3:before {
  content: var(--icon-plus-3);
  color: #dddfe9;
}
.icon-product:before {
  content: var(--icon-product);
  color: #ff3d57;
}
.icon-protest:before {
  content: var(--icon-protest);
}
.icon-bill-1:before {
  content: var(--icon-bill-1);
  color: #09b66d;
}
.icon-bill-2:before {
  content: var(--icon-bill-2);
}
.icon-bin:before {
  content: var(--icon-bin);
  color: var(--silver);
}
.icon-gear:before {
  content: var(--icon-gear);
}
.icon-dashboard-1:before {
  content: var(--icon-dashboard-1);
}
.icon-dashboard-2:before {
  content: var(--icon-dashboard-2);
}
.icon-dashboard-3:before {
  content: var(--icon-dashboard-3);
}
.icon-dashboard-4:before {
  content: var(--icon-dashboard-4);
}
.icon-bookmark:before {
  content: var(--icon-bookmark);
}
.icon-menu:before {
  content: var(--icon-menu);
}
.icon-search:before {
  content: var(--icon-search);
}
.icon-check:before {
  content: var(--icon-check);
}
.icon-bell-o:before {
  content: var(--icon-bell-o);
}
.icon-angle-left:before {
  content: var(--icon-angle-left);
}
.icon-angle-right:before {
  content: var(--icon-angle-right);
}
.icon-angle-down:before {
  content: var(--icon-angle-down);
}
