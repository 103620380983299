.login-page {
  max-height: 100vh;
  flex-wrap: wrap;
}
.login-page__right-item {
  flex-basis: 60%;
  padding: 40px;
  position: relative;
  background: #fff;
}
.login-page__right-item .logo {
  width: 160px;
  height: 70px;
  margin: 0 auto 25px;
}
.login-page__right-item .logo img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.login-page__right-item .title {
  color: #bfc2d1;
  text-align: center;
  font-size: 31px;
}
.login-page__right-item .title2 {
  color: var(--color-primary);
  text-align: center;
  font-size: 25px;
  font-weight: 500;
  margin-top: 20px;
}
.login-page__right-item .login-form {
  padding: 40px 200px;
}
.login-page__right-item .login-form .icons {
  position: absolute;
  left: 3px;
  top: 11px;
  font-size: 18px;
}
.login-page__right-item .login-form .inputContainer {
  position: relative;
}
.login-page__right-item .login-form .inputContainer .fa-eye-slash, .login-page__right-item .login-form .inputContainer .fa-eye {
  position: absolute;
  right: 3px !important;
  top: 12px;
  color: #97979e;
  cursor: pointer;
}
.login-page__right-item .login-form label {
  color: var(--silver);
}
.login-page__right-item .login-form input {
  width: 100%;
  border: 0;
  border-radius: 0;
  border-bottom: 1px solid var(--silver);
  padding: 5px 10px;
  background-color: white !important;
  direction: ltr;
}
.login-page__right-item .login-form input:-internal-autofill-selected {
  -webkit-appearance: menulist-button;
  background-color: white !important;
  background-image: none !important;
  color: black !important;
}
.login-page__right-item .login-form .link-forget {
  color: var(--primary);
  font-weight: 500;
  font-size: 14px;
}
.login-page__right-item .login-form .link-forget:hover {
  text-decoration: none;
}
.login-page__right-item .login-form .btn-login {
  background-color: var(--primary);
  color: white;
  width: 100%;
  margin-top: 25px;
  border-radius: var(--round-1);
  border: 0;
  padding: 15px;
  cursor: pointer;
}
.login-page__right-item .footer-login {
  color: var(--silver);
  text-align: center;
  position: absolute;
  font-size: 12px;
  left: 0;
  right: 0;
}
.login-page__left-item {
  flex-basis: 40%;
  position: relative;
}
.login-page__left-item .image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.login-page .remember-password {
  position: relative;
  color: black !important;
}
.login-page .remember-password input {
  opacity: 0;
  margin: 0 !important;
}
.login-page [type='checkbox'].filled-in + span:not(.lever):after {
  border-radius: 2px;
}
.login-page [type='checkbox'].filled-in + span:not(.lever):before, .login-page [type='checkbox'].filled-in + span:not(.lever):after {
  content: '';
  right: 0;
  position: absolute;
  transition: border 0.25s, background-color 0.25s, width 0.2s 0.1s, height 0.2s 0.1s, top 0.2s 0.1s, left 0.2s 0.1s;
  z-index: 1;
}
.login-page [type='checkbox'].filled-in:not(:checked) + span:not(.lever):before {
  width: 0;
  height: 0;
  border: 3px solid transparent;
  right: 10px;
  top: 10px;
  transform: rotateZ(37deg);
  transform-origin: 100% 100%;
}
.login-page [type='checkbox'].filled-in:not(:checked) + span:not(.lever):after {
  height: 15px;
  width: 15px;
  background-color: transparent;
  border: 2px solid var(--color-primary);
  top: 5px;
  z-index: 0;
  border-radius: 3px;
}
.login-page [type='checkbox'].filled-in:checked + span:not(.lever):before {
  top: 3px;
  right: 8px;
  width: 6px;
  height: 11px;
  border-top: 2px solid transparent;
  border-left: 2px solid transparent;
  border-right: 2px solid var(--gray-100);
  border-bottom: 2px solid var(--gray-100);
  transform: rotateZ(37deg);
  transform-origin: 100% 100%;
}
.login-page [type='checkbox'].filled-in:checked + span:not(.lever):after {
  top: 5px;
  height: 15px;
  width: 15px;
  border: 2px solid var(--sidebar-background);
  background-color: var(--sidebar-background);
  z-index: 0;
  border-radius: 3px;
}
.login-page [type='checkbox'].filled-in.tabbed:focus + span:not(.lever):after {
  border-radius: 2px;
  border-color: var(--sidebar-background);
  background-color: rgba(0, 0, 0, 0.1);
}
.login-page [type='checkbox'].filled-in.tabbed:checked:focus + span:not(.lever):after {
  border-radius: 2px;
  background-color: var(--sidebar-background);
  border-color: var(--sidebar-background);
}
.login-page [type='checkbox'].filled-in:disabled:not(:checked) + span:not(.lever):before {
  background-color: transparent;
  border: 2px solid transparent;
}
.login-page [type='checkbox'].filled-in:disabled:not(:checked) + span:not(.lever):after {
  border-color: #e1e7ed;
  background-color: #e1e7ed;
}
.login-page [type='checkbox'].filled-in:disabled:checked + span:not(.lever):before {
  background-color: transparent;
}
.login-page [type='checkbox'].filled-in:disabled:checked + span:not(.lever):after {
  background-color: var(--checkbox);
  border-color: var(--checkbox);
}
.login-page [type='checkbox'].filled-in:disabled:checked + span {
  cursor: not-allowed;
  color: #999;
}
.login-page [type='checkbox'].filled-in:disabled:not(:checked) + span {
  cursor: not-allowed;
  color: #999;
}
.login-page .invalid-feedback {
  display: block;
}
* {
  outline: none;
}
.alert-primary {
  background: #f5ecdd!important;
  border-color: #f2e7d4;
  color: #6d5936;
}

@media (min-width: 330px) {
  .login-page__right-item .title2 {
    font-size: 18px;
  }
}
@media (max-width: 768px) {
  .login-page {
    flex-wrap: nowrap;
  }
  .login-page__right-item {
    padding: 40px 10px;
    z-index: 2;
    background: #fff;
    margin-top: 200px;
    border-top-right-radius: 35px;
    border-top-left-radius: 35px;
    flex-basis: 100%;
    width: 100%;
    position: absolute;
  }

}
@media screen and (max-width: 991.98px) {
  .login-page__right-item .login-form {
    padding: 40px 50px;
  }
  .login-page__left-item {
    width: 100%;
    flex-basis: 100%;
  }
  .login-page {
    flex-wrap: nowrap;
  }
  .login-page__right-item {
    background: #fff;
    border-top-left-radius: 35px;
    border-top-right-radius: 35px;
    flex-basis: 100%;
    margin-top: 200px;
    padding: 40px 10px;
    position: absolute;
    width: 100%;
    z-index: 2;
  }
  .login-page__left-item {
    flex-basis: 100%;
    width: 100%;
  }
}
@media screen and (max-width: 576px) {

  .login-page__right-item .title{
    font-size: 20px;
  }

  .login-page__right-item .title2 {
    font-size: 18px;
  }

  .login-page__right-item .login-form {
    padding: 40px 20px;
  }
}
@media screen and (min-width: 992px) {
  .login-page__right-item .title{
    font-size: 32px;
  }

  .login-page__right-item .login-form {
    padding: 40px 100px;
  }
}
@media (min-width: 576px) {
  .login-page__right-item .login-form {
    padding: 40px 20px;
  }
}
@media (min-width: 1200px) {
  .login-page__right-item .login-form {
    padding: 40px 170px;
  }
}


