button.btn:hover, button.btn:focus, button.btn:active, button.btn:disabled {
  color: white;
}
button.btn.btn-submit {
  background-color: var(--primary);
  color: white;
  border: 0 var(--primary);
  padding: 15px;
  border-radius: 10px;
  font-size: 16px;
  cursor: pointer;
  margin: 10px;
}
button.btn.btn-submit:hover {
  background-color: var(--primary);
  border-color: var(--primary);
}
button.btn.btn-submit:focus {
  background-color: var(--primary);
  border-color: var(--primary);
}
button.btn.btn-submit:active:focus {
  background-color: var(--primary);
  border-color: var(--primary);
}
button.btn.btn-cancel {
  background-color: var(--primary);
  color: white;
  border: 0 var(--primary);
  padding: 15px;
  border-radius: 10px;
  font-size: 16px;
  cursor: pointer;
  margin: 10px;
}
button.btn.btn-cancel:hover {
  background-color: var(--primary);
  border-color: var(--primary);
}
button.btn.btn-cancel:focus {
  background-color: var(--primary);
  border-color: var(--primary);
}
button.btn.btn-cancel:active:focus {
  background-color: var(--primary);
  border-color: var(--primary);
}

