.main .main-sidebar {
  display: none;
  z-index: 11;
  background-color: var(--sidebar-background);
  transition: all 0.3s;
  position: fixed;
  width: var(--width-sidebar);
  top: 0;
  bottom: 0;
}
.main .main-sidebar .menu-button {
  display: none;
}
.main .main-sidebar .inner-scroll {
  direction: ltr;
  overflow-y: auto;
  height: 100%;
  width: 100%;
  /* width */
  /* Handle */
}
.main .main-sidebar .inner-scroll::-webkit-scrollbar {
  width: 10px;
}
.main .main-sidebar .inner-scroll::-webkit-scrollbar-thumb {
  background: var(--darkblue-lighten-30);
  border-radius: 10px;
}
.main .main-sidebar .inner-scroll a {
  color: var(--sidebar-color);
}
.main .main-sidebar .inner-scroll a:hover {
  text-decoration: none;
}
.main .main-sidebar .inner-scroll .logo {
  width: 140px;
  height: 50px;
  overflow: hidden;
  margin: 30px auto 15px;
}
.main .main-sidebar .inner-scroll .logo img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.main .main-sidebar .inner-scroll .avatar {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin: 25px auto 15px;
}
.main .main-sidebar .inner-scroll .avatar img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}
.main .main-sidebar .inner-scroll .name {
  color: var(--sidebar-color);
  text-align: center;
  font-size: 19px;
}
.main .main-sidebar .inner-scroll .email {
  color: white;
  text-align: center;
  font-size: 12px;
}
.main .main-sidebar .inner-scroll .content-wrapper {
  width: 100%;
  margin-top: 35px;
}
.main .main-sidebar .inner-scroll .content-wrapper .navigator {
  margin-bottom: 0;
  padding-right: 0;
  list-style: none;
  color: var(--sidebar-color);
}
.main .main-sidebar .inner-scroll .content-wrapper .navigator > .item {
  position: relative;
  margin: 0 10px;
}
.main .main-sidebar .inner-scroll .content-wrapper .navigator > .item.open > .item-title .icon-left {
  transform: rotate(-90deg);
  transition: 0.5s;
}
.main .main-sidebar .inner-scroll .content-wrapper .navigator > .item:last-child {
  margin-bottom: 0;
}
.main .main-sidebar .inner-scroll .content-wrapper .navigator > .item > .item-title {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 100%;
  line-height: 25px;
  padding: 5px 0;
  transition: border ease 0.3s, background ease 0.3s;
}
.main .main-sidebar .inner-scroll .content-wrapper .navigator > .item > .item-title::before {
  content: none;
}
.main .main-sidebar .inner-scroll .content-wrapper .navigator > .item > .item-title[role='button'] i.icon-angle-down {
  position: absolute;
  left: 15px;
}
.main .main-sidebar .inner-scroll .content-wrapper .navigator > .item > .item-title .icon {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  padding: 0 10px;
  width: 40px;
}
.main .main-sidebar .inner-scroll .content-wrapper .navigator > .item > .item-title .icon img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}
.main .main-sidebar .inner-scroll .content-wrapper .navigator > .item > .item-title .icon i {
  display: inline-block;
  width: 100%;
  height: 100%;
  text-align: center;
  font-size: 20px;
  transition: all 0.3s;
}
.main .main-sidebar .inner-scroll .content-wrapper .navigator > .item > .item-title .icon i::before {
  margin: 0;
  width: 100%;
  height: 100%;
  line-height: 35px;
  font-size: inherit;
}
.main .main-sidebar .inner-scroll .content-wrapper .navigator > .item > .item-title .text {
  height: 24px;
  font-size: 13px;
  padding: unset;
  text-align: right;
  line-height: 24px;
  position: relative;
  width: calc(75% - 35px);
  overflow: hidden;
  transition: all 0.25s;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.main .main-sidebar .inner-scroll .content-wrapper .navigator > .item > .item-title .icon-left {
  font-size: 17px;
  transition: 0.5s;
}
.main .main-sidebar .inner-scroll .content-wrapper .navigator > .item.active > .item-title > .text {
  color: var(--sidebar-selected-color);
}
.main .main-sidebar .inner-scroll .content-wrapper .navigator > .item.active > .item-title > .icon {
  color: var(--sidebar-selected-color);
}
.main .main-sidebar .inner-scroll .content-wrapper .navigator > .item > .show .active {
  color: var(--sidebar-selected-color);
}
.main .main-sidebar .inner-scroll .content-wrapper .navigator > .item > .show .active .text {
  color: var(--sidebar-selected-color);
}
.main .main-sidebar .inner-scroll .content-wrapper .navigator > .item .sidebar-subnav {
  padding-left: 48px;
}
.main .main-sidebar .inner-scroll .content-wrapper .navigator > .item .sidebar-subnav > .item {
  font-size: 12px;
  padding: 10px 4px;
}
.main .main-sidebar.collapsed {
  display: block;
}
.main .main-sidebar.collapsed .header .logo .text {
  opacity: 0;
}
.main .main-sidebar.collapsed .content-wrapper .navigator > .item::before {
  display: none;
}
.main .main-sidebar.collapsed .content-wrapper .navigator > .item > .item-title {
  display: flex;
}
.main .main-sidebar.collapsed .content-wrapper .navigator > .item > .item-title i.icon-angle-down {
  display: none;
}
.main .main-sidebar.collapsed .content-wrapper .navigator > .item > .item-title span.icon > i {
  font-size: 24px;
}
.main .main-sidebar.collapsed .content-wrapper .navigator > .item > .item-title .badge-count {
  position: absolute;
  top: 10px;
  left: 10px;
  margin: 0 !important;
}
.main .main-sidebar.collapsed .content-wrapper .navigator > .item .submenu {
  display: block;
  position: initial;
  width: initial;
  top: initial;
  right: initial;
}
.main .main-sidebar.collapsed .content-wrapper .navigator > .item .submenu::before {
  opacity: 1;
  visibility: visible;
}
.main .main-sidebar.collapsed .content-wrapper .navigator > .item .submenu > .item .item-title::before, .main .main-sidebar.collapsed .content-wrapper .navigator > .item .submenu > .item .item-title::after {
  display: none;
}
.main .main-sidebar .collapse-icon.active {
  color: var(--primary);
}

/*@include breakpoint(medium)*/
@media (min-width: 768px) {
  .main .main-sidebar {
    display: block;
    top: 0;
  }

  .main .main-sidebar .inner-scroll .content-wrapper .navigator>.item>.item-title {
    justify-content: flex-start;
  }

  .main .main-sidebar .inner-scroll .content-wrapper .navigator>.item>.item-title:before {
    content: '';
  }
}

/*@include breakpoint(large)*/
@media (min-width: 992px) {
  .main .main-sidebar.collapsed {
    width: 70px;
  }

  .main-sidebar .content-wrapper .navigator .submenu {
    width: 220px;
    padding: 0;
    position: absolute;
    right: 100%;
    top: 0;
  }
}

@media (max-width: 767.98px) {
  .main.collapsed-sidebar .main-sidebar {
    display: block;
    right: 0;
  }
  .main.collapsed-sidebar .main-sidebar .avatar,
  .main.collapsed-sidebar .main-sidebar .logo {
    display: none;
  }
  .main.collapsed-sidebar .main-sidebar .menu-button {
    background: transparent;
    border: 0;
    color: #d8d8d8;
    cursor: pointer;
    display: block;
    font-size: 25px;
    padding: 5px;
  }
  .main.collapsed-sidebar .main-sidebar .name {
    padding-top: 20px;
  }
  .main.collapsed-sidebar {
    -webkit-filter: unset;
    filter: unset;
    margin-left: 0;
  }

  .main.collapsed-sidebar .main-content,
  .main.collapsed-sidebar .mobile-header {
    -webkit-filter: blur(3px);
    filter: blur(3px);
    margin-left: 0;
  }
}

