body {
  overflow-y: auto;
  font: inherit;
}
.ltr {
  direction: ltr;
  text-align: left;
}
.ltr [class^='modal---modal-overlay'] [class*='modal---modal-content'] {
  max-width: 600px;
}
.ltr [class^='modal---modal-overlay'] [class*='modal---modal-content'] [class^='modal---close'] {
  right: 24px;
  left: auto;
}
.ltr [type='checkbox'].filled-in + span:not(.lever):before, .ltr [type='checkbox'].filled-in + span:not(.lever):after {
  right: auto;
  left: 0;
}
.ltr [type='checkbox'] + span {
  padding-left: 25px;
  padding-right: 0;
}
.ltr .MuiTypography {
  font-family: Arial, Helvetica, sans-serif;
}
.ltr .MuiInput-root {
  font-family: Arial, Helvetica, sans-serif;
}
.ltr .main .main-sidebar .inner-scroll .content-wrapper {
  direction: ltr;
}
.ltr .main .main-sidebar .inner-scroll .content-wrapper .navigator {
  padding-left: 0;
}
.ltr .main .main-sidebar .inner-scroll .content-wrapper .navigator > .item > .item-title .text {
  text-align: left;
}
.ltr .main .main-content {
  border-radius: var(--theme-radius) 0 0 var(--theme-radius);
  margin-left: auto;
  margin-right: 0;
}
.ltr .main .main-content .profile .dropdown-button .image-user {
  margin-right: 10px;
  margin-left: 0;
}
.ltr .main .main-content .profile .dropdown-button .description {
  padding: 0 30px 0 0;
}
.ltr .main .main-content .profile .dropdown-button .description i {
  left: auto;
  right: 0;
}
.ltr .main .main-content .profile .dropdown-list {
  left: 0;
  right: auto;
}
.ltr .main .main-content .dashboard-page .box-item .title {
  margin-right: 0;
  margin-left: 5px;
}
.ltr .main .main-content .dashboard-page .legend > table {
  left: 29px !important;
  right: auto !important;
}
.ltr .main .main-content .info-page__box-right .bottom-box .item i {
  margin-right: 7px;
  margin-left: 0;
}
.ltr .main .main-content .clients-page .section-one__items__box i {
  margin-right: 15px;
  margin-left: 0;
}
.ltr .main .main-content .grid-view .MuiPaper-root .MuiTableCell-root {
  font-size: 12px;
  text-align: left;
}
.ltr .main .main-content .report-page .box-left .top-row .box-item .title {
  margin-left: 5px;
  margin-right: 0;
}
.ltr .main .main-content .report-page .box-left .top-row .box-item .description {
  margin-left: 5px;
  margin-right: 0;
}
.ltr .main .main-content .report-page .box-right .btn-primary i.icon-angle-right {
  margin-left: 0 !important;
  margin-right: 0.5rem !important;
  transform: rotate(180deg);
  display: inline-block;
}
.ltr .main .main-content .report-page .box-right .btn-primary i.icon-angle-left {
  margin-right: 0 !important;
  margin-left: 0.5rem !important;
  transform: rotate(180deg);
  display: inline-block;
}
.ltr .main .main-content .report-page .box-right .button-slide i {
  transform: rotate(180deg);
  margin: 0 6px !important;
}
.ltr .main .main-content .report-page .box-right .box-datepicker i {
  margin-left: 0;
  margin-right: 10px;
}
.ltr .main .main-content .form-check-input {
  margin-left: -1.25rem;
  margin-right: 0;
}
.ltr .main .main-content #formAwards .icon-plus-2 {
  margin-left: 20px !important;
  margin-right: 5px !important;
}
.ltr .main .main-footer {
  padding-left: var(--width-sidebar);
}
.ltr .workingHours .fa-pencil-alt {
  display: inline-block;
  position: absolute;
  top: 20px;
  right: 20px;
  left: auto;
  color: #888;
}
.ltr .customer-registration .MuiPaper-root {
  padding: 0;
}
.ltr .first-login-page {
  margin-left: var(--width-sidebar);
}
.ltr .first-login-page .section-one {
  border-right: 2px solid var(--sidebar-background);
}
.ltr .first-login-page button {
  float: right;
}
.rtl {
  direction: rtl;
  text-align: right;
}
.rtl [class^='modal---modal-overlay'] [class*='modal---modal-content'] {
  max-width: 600px;
  text-align: right !important;
  font-size: 13px;
}
.rtl [class^='modal---modal-overlay'] [class*='modal---modal-content'] input {
  font-size: 13px;
}
.rtl [class^='modal---modal-overlay'] [class*='modal---modal-content'] textarea {
  font-size: 13px;
}
.rtl [class^='modal---modal-overlay'] [class*='modal---modal-content'] .badge {
  line-height: 1.5;
}
.rtl [class^='modal---modal-overlay'] [class*='modal---modal-content'] .checkbox-group [type='checkbox'] + span {
  font-size: 13px;
}
.rtl [class^='modal---modal-overlay'] [class*='modal---modal-content'] [class^='modal---modal-header'] {
  padding: 16px 24px 16px 56px !important;
}
.rtl [class^='modal---modal-overlay'] [class*='modal---modal-content'] [class^='modal---close'] {
  left: 24px;
  right: auto;
}
.rtl .messageDate {
  text-align: left;
}
.rtl .main .main-sidebar .inner-scroll .content-wrapper {
  direction: rtl;
}
.rtl .main .main-sidebar .inner-scroll .content-wrapper .navigator {
  padding-right: 0;
}
.rtl .main .main-sidebar .inner-scroll .content-wrapper .navigator > .item > .item-title .text {
  text-align: right;
}
.rtl .main .main-sidebar .inner-scroll .content-wrapper .navigator > .item .sidebar-subnav {
  padding-left: 0;
  padding-right: 48px;
}
.rtl .main .main-sidebar .inner-scroll .content-wrapper .navigator > .item .sidebar-subnav > .item {
  font-size: 12px;
  padding: 10px 4px;
}
.rtl .main .main-content {
  border-radius: 0 var(--theme-radius) var(--theme-radius) 0;
  margin-left: 0;
  margin-right: auto;
}
.rtl .main .main-content .profile .dropdown-button .image-user {
  margin-right: 0;
  margin-left: 10px;
}
.rtl .main .main-content .profile .dropdown-button .description i {
  left: 0;
  right: auto;
}
.rtl .main .main-content .profile .dropdown-list {
  left: auto;
  right: -10px;
}
.rtl .main .main-content .dashboard-page .box-item .title {
  margin-right: 5px;
  margin-left: 0;
}
.rtl .main .main-content .dashboard-page .legend > table {
  right: 29px !important;
  left: auto !important;
}
.rtl .main .main-content .dashboard-page .select-box {
  margin-right: 0.5rem;
}
.rtl .main .main-content .info-page__box-right .bottom-box .item i {
  margin-right: 0;
  margin-left: 7px;
}
.rtl .main .main-content .clients-page .section-one__items__box i {
  margin-right: 0;
  margin-left: 15px;
}
.rtl .main .main-content .grid-view .MuiPaper-root .MuiTableCell-root {
  font-size: 12px;
  text-align: right;
}
.rtl .main .main-content .report-page .box-left .top-row .box-item .title {
  margin-left: 0;
  margin-right: 5px;
}
.rtl .main .main-content .report-page .box-left .top-row .box-item .description {
  margin-left: 0;
  margin-right: 5px;
}
.rtl .main .main-content .report-page .box-right .button-slide i {
  transform: rotate(180deg);
  margin: 0 6px !important;
}
.rtl .main .main-content .report-page .box-right .box-datepicker i {
  margin-left: 10px;
  margin-right: 0;
}
.rtl .main .main-content .form-check-input {
  margin-left: 0;
  margin-right: -1.25rem;
}
.rtl .main .main-content #formAwards .icon-plus-2 {
  margin-left: 5px !important;
  margin-right: 20px !important;
}
.rtl .main .main-content .MuiTableCell-footer .MuiTablePagination-toolbar .MuiIcon-root {
  transform: rotate(180deg);
}
.rtl .main .main-footer {
  padding-right: var(--width-sidebar);
}
.rtl .customer-registration .MuiPaper-root {
  padding: 0;
}
.rtl .first-login-page {
  margin-right: var(--width-sidebar);
}
.rtl .first-login-page .section-one {
  border-left: 2px solid var(--sidebar-background);
}
.rtl .first-login-page button {
  float: left;
}

/*@include media-breakpoint-down(sm)*/
@media (max-width: 768px) {
  .ltr [type='checkbox'] + span {
    padding-left: 20px;
    line-height: 20px;
  }

  .ltr .modalFooter .modalMore {
    text-align: left;
  }

  .ltr .main.collapsed-sidebar {
    left: 0;
    right: auto;
  }

  .ltr .main.collapsed-sidebar .menu-button{
    margin-left: auto;
  }

  .ltr .main .main-content{
    border-radius: 25px 25px 0 0;
  }

  .ltr .MuiFormControl-root[class*='MTableToolbar-searchField-'] {
    min-width: auto;
    width: 130px;
    padding-left: 4px;
  }

  .ltr .MuiFormControl-root[class*='MTableToolbar-searchField-'] .MuiInputBase-input{
    font-size: 13px;
  }

  .ltr .MuiFormControl-root[class*='MTableToolbar-searchField-'] .MuiInputAdornment-root .MuiIconButton-root {
    padding: 4px 0;
  }

  .ltr .main .main-footer {
    padding-left: 10px;
  }

  .rtl .main .main-content {
    border-radius: 0 0 25px 25px;
  }

  /*.rtl .MuiFormControl-root[class*='MTableToolbar-searchField-'] {*/
  /*  min-width: auto;*/
  /*  width: 130px;*/
  /*  padding-left: 4px;*/
  /*}*/

  .rtl .MuiFormControl-root[class*='MTableToolbar-searchField-'] .MuiInputBase-input{
    font-size: 13px;
  }

  /*.rtl .MuiFormControl-root[class*='MTableToolbar-searchField-'] .MuiInputAdornment-root .MuiIconButton-root{*/
  /*  padding: 4px 0;*/
  /*}*/

  .rtl .main .main-footer {
    padding-right: 10px;
  }

  .rtl .profile .dropdown-list {
    right: auto;
    left: 0;
  }

  .rtl .react-datepicker-popper {
    left: -200px !important;
    top: 30px !important;
    direction: ltr;
  }

  .rtl .react-datepicker__month-year-read-view--down-arrow {
    margin-left: 140px !important;
  }

  .rtl .box-left-wrapper {
    margin-right: 0
  }

}

/*@include breakpoint(medium)*/
@media screen and (min-width: 768px) {
  .ltr .customer-registration {
    margin-left: 1rem;
    margin-right: 1rem;
  }
}

/*@include breakpoint(large)*/
@media screen and (min-width: 992px)  {
  .ltr .customer-registration {
    margin-right: -1rem;
    margin-bottom: -4rem;
    margin-left: -1rem;
  }

  .lrt .MuiPaper-root {
    padding: 0 1.5rem;
  }


  .rtl .customer-registration .MuiPaper-root {
    padding: 0 1.5rem;
  }
}
