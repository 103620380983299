.text-success {
  color: var(--success) !important;
}
.text-danger {
  color: var(--success) !important;
}
.text-primary {
  color: var(--primary) !important;
}

.badge-success {
  background-color: var(--success) !important;
}

.alert-warning {
  background-color: #f6eee1;
  border-color: #f2e7d4;
  color: #6d5936;
}

.ltr .ml-2, .ltr .mx-2,
.rtl .mr-2, .rtl .mx-2 {
  margin-left: .5rem!important;
}
.ltr .ml-3, .ltr .mx-3, .rtl .mr-3, .rtl .mx-3 {
  margin-left: 1rem!important;
}

.rtl .ml-2, .rtl .mx-2 {
  margin-right: 0.5rem!important;
}

.rtl .ml-3, .rtl .mx-3 {
  margin-right: 1rem!important;
}

.ltr .mr-3, .mx-3 {
  margin-right: 1rem!important;
}

.ltr .pl-1, .ltr .px-1, .rtl .pr-1, .rtl .px-1 {
  padding-left: 0.25rem!important;
}



.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: none;
}
.col, .col-1, .col-10, .col-11, .col-12, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-auto, .col-lg, .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-auto, .col-md, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-auto, .col-sm, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-auto, .col-xl, .col-xl-1, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.border-light {
  border-color: #ccd2d9!important;
}

/*@media (min-width: 576px) {*/
/*  .rtl .pl-sm-0, .px-sm-0 {*/
/*    padding-right: 0 !important;*/
/*  }*/
/*}*/
