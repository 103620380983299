.text-primary.spinner-border,
.text-primary.spinner-grow {
  color: var(--primary) !important;
}
.text-secondary.spinner-border ,
.text-secondary.spinner-grow {
  color: var(--secondary) !important;
}
.text-success.spinner-border,
.text-success.spinner-grow {
  color: var(--success) !important;
}
.text-warning.spinner-border,
.text-warning.spinner-grow {
  color: var(--warning) !important;
}
.text-danger.spinner-border,
.text-danger.spinner-grow {
  color: var(--danger) !important;
}
.text-info.spinner-border,
.text-info.spinner-grow {
  color: var(--info) !important;
}
.text-dark.spinner-border,
.text-dark.spinner-grow {
  color: var(--dark) !important;
}
.text-light.spinner-border,
.text-light.spinner-grow {
  color: var(--light) !important;
}


/*Bootstrap Styles -- Do not modify anything below*/

@-webkit-keyframes spinner-border {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg)
  }
}

@keyframes spinner-border {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg)
  }
}

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: .25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: .75s linear infinite spinner-border;
  animation: .75s linear infinite spinner-border
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: .2em
}

@-webkit-keyframes spinner-grow {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0)
  }
  50% {
    opacity: 1;
    -webkit-transform: none;
    transform: none
  }
}

@keyframes spinner-grow {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0)
  }
  50% {
    opacity: 1;
    -webkit-transform: none;
    transform: none
  }
}

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  -webkit-animation: .75s linear infinite spinner-grow;
  animation: .75s linear infinite spinner-grow
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border, .spinner-grow {
    -webkit-animation-duration: 1.5s;
    animation-duration: 1.5s
  }
}
