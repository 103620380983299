.icon-alarm {
  width: 50px;
  height: 50px;
  display: none;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: white;
  box-shadow: 0 1px 5px -3px #5a5a5a;
  margin: 0 10px;
}
.icon-alarm i {
  color: #9b9da7;
  font-size: 25px;
}
.profile {
  background-color: white;
  height: 50px;
  border-radius: 30px;
  box-shadow: 0 1px 5px -3px #5a5a5a;
}
.profile .dropdown-list {
  display: none;
  background-color: #fff;
  position: absolute;
  left: 0;
  right: auto;
  border-radius: 10px;
  box-shadow: 0 1px 8px -4px grey;
  padding: 5px 15px;
  z-index: 10;
}
.profile .dropdown-list:hover {
  display: block;
}
.profile .dropdown-list .dropdown-item {
  padding: 8px;
  color: black;
  border-radius: 10px;
}
.profile .dropdown-list .dropdown-item:hover {
  background-color: #fff;
}
.profile .dropdown-list .dropdown-item:active {
  background-color: #fff !important;
}
.profile .dropdown-list hr {
  border-top: 1px solid #eee;
  margin: 8px 0;
}
.profile .dropdown-button {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  transition: all ease 0.3s;
  height: 100%;
  padding: 0 10px;
  cursor: pointer;
}
.profile .dropdown-button::after {
  display: none;
}
.profile .dropdown-button:hover + .dropdown-list {
  display: block;
}
.profile .dropdown-button .image-user {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  object-fit: cover;
  margin-left: 10px;
}
.profile .dropdown-button .description {
  display: block;
  width: auto;
  max-width: none;
  padding: 0 0 0 30px;
  position: relative;
  font-size: 14px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.profile .dropdown-button .description p {
  margin-bottom: 0;
}
.profile .dropdown-button .description .name {
  color: black;
}
.profile .dropdown-button .description .work {
  color: #9b9da7;
  font-size: 12px;
}
.profile .dropdown-button .description i {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translate(0, -40%);
  color: inherit;
  font-size: 18px;
  line-height: 20px;
}
.profile .dropdown-button:hover {
  text-decoration: none;
}
.profile .dropdown-menu {
  width: calc(100% - 5px);
  border-radius: 0;
}
.messages .dropdown-list {
  display: none;
  background-color: #fff;
  position: absolute;
  left: 0;
  right: auto;
  margin-top: 1px;
  border-radius: 10px;
  box-shadow: 0 1px 8px -4px grey;
  z-index: 10;
  min-width: 200px;
}
.messages .dropdown-list:hover {
  display: block;
}
.messages .dropdown-list .dropdown-item {
  padding: 8px 15px;
  color: black;
  border-radius: 10px;
  font-size: 12px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.03);
}
.messages .dropdown-list .dropdown-item:hover {
  background-color: #fff;
}
.messages .dropdown-list .dropdown-item:active {
  background-color: #fff !important;
}
.messages .dropdown-list .dropdown-item.moreBtn {
  color: var(--primary);
  text-align: center;
}
.messages .dropdown-button {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  transition: all ease 0.3s;
  height: 100%;
  padding: 0 10px;
  cursor: pointer;
}
.messages .dropdown-button::after {
  display: none;
}
.messages .dropdown-button:hover + .dropdown-list {
  display: block;
}
.messages .dropdown-button .image-user {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  object-fit: cover;
  border: 1px solid gray;
  margin-left: 10px;
}
.messages .dropdown-button .description {
  display: block;
  width: auto;
  max-width: none;
  padding: 0 0 0 30px;
  position: relative;
  font-size: 14px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.messages .dropdown-button .description p {
  margin-bottom: 0;
}
.messages .dropdown-button .description .name {
  color: black;
}
.messages .dropdown-button .description .work {
  color: #9b9da7;
  font-size: 12px;
}
.messages .dropdown-button .description i {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translate(0, -40%);
  color: inherit;
  font-size: 18px;
  line-height: 20px;
}
.messages .dropdown-button:hover {
  text-decoration: none;
}
.messages .dropdown-menu {
  width: calc(100% - 5px);
  border-radius: 0;
}
.section-two__item1 {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}
.section-two__item1__box {
  flex-basis: 45%;
  margin: 5px;
  background-color: white;
  border-radius: 15px;
  padding: 15px;
}
.section-two__item1__box i {
  font-size: 40px;
}
.section-two__item1__box .title {
  font-size: 18px;
}
.section-two__item1__box .description {
  color: #9b9da7;
  margin-bottom: 5px;
  font-size: 15px;
}
.section-two__item3 {
  display: flex;
  flex-wrap: wrap;
}
.section-two__item3__box {
  flex-basis: 46%;
  margin: 5px 3px;
  background-color: white;
  border-radius: 15px;
  padding: 10px 5px;
  text-align: center;
}
.section-two__item3__box i {
  font-size: 30px;
}
.section-two__item3__box.active {
  background-color: #d2ab67;
}
.section-two__item3__box.active .title {
  color: white;
}
.section-two__item3__box .title {
  color: #9b9da7;
  font-size: 11px;
  text-align: center;
  font-weight: 500;
  margin-top: 10px;
}
.section-three__item1 {
  background-color: white;
  border-radius: 15px;
  padding: 15px;
}
.section-three__item1 .title {
  font-size: 18px;
}
.section-three__item1 .table {
  background-color: white;
  text-align: center;
}
.section-three__item1 .table thead {
  background-color: #f7f8f9;
}
.section-three__item1 .table thead th {
  padding: 5px;
  text-align: center;
}
.section-three__item1 .table td {
  font-size: 15px;
  padding: 15px 0.75rem;
}
.section-three__item1 .table .icon {
  width: 25px;
  height: 25px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  border-radius: 50%;
  justify-content: center;
  color: white;
}
.section-three__item1 .table .icon-green {
  background-color: #3dd598;
}
.section-three__item1 .table .icon-red {
  background-color: #fc5a5a;
}
.section-three__item1 .table .icon-yellow {
  background-color: #ff974a;
}
.section-three__item2 {
  background-color: white;
  border-radius: 15px;
  padding: 15px;
}
.section-three__item2 .box-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.section-three__item2 .box-top i {
  font-size: 25px;
  margin-left: 10px;
}
.section-three__item2 .box-top p {
  margin-bottom: 0;
}
.section-three__item2 .box-top .title {
  font-weight: bold;
  font-size: 14px;
}
.section-three__item2 .box-top .description {
  font-size: 12px;
  color: #9b9da7;
}
.section-three__item2 .box-top .btn-signup {
  border-radius: 25px;
  border: 1px solid var(--primary);
  color: var(--primary);
  padding: 4px 15px;
  font-size: 14px;
  background: transparent;
  text-decoration: none;
  cursor: pointer;
}
.section-three__item2 .box-top .btn-signup:focus {
  outline: none;
  box-shadow: none;
}
.section-three__item2 .image-profile {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
  margin: 0 10px;
}
.section-three__item2 .name {
  color: var(--primary);
  font-size: 13px;
  font-weight: bold;
}
.section-three__item2 .number-mobile {
  font-size: 13px;
}
.section-three__item2 .table {
  background-color: white;
}
.section-three__item2 .table thead {
  background-color: #f7f8f9;
}
.section-three__item2 .table thead th {
  padding: 5px;
}
.section-three__item2 .table td {
  font-size: 15px;
  padding: 15px 0.75rem;
}
.section-three__item2 .table td .icons {
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
  margin-top: 10px;
}
.highcharts-figure, .highcharts-data-table table {
  min-width: 320px;
  margin: 6px auto 0;
  height: 285px;
}
#container {
  border-radius: 15px;
  height: 280px;
}
.highcharts-data-table table {
  border-collapse: collapse;
  border: 1px solid #ebebeb;
  margin: 10px auto;
  text-align: center;
  width: 100%;
  max-width: 500px;
}
.highcharts-data-table caption {
  padding: 1em 0;
  font-size: 1.2em;
  color: #555;
}
.highcharts-data-table th {
  font-weight: 600;
  padding: 0.5em;
}
.highcharts-data-table td, .highcharts-data-table th, .highcharts-data-table caption {
  padding: 0.5em;
}
.highcharts-data-table thead tr, .highcharts-data-table tr:nth-child(even) {
  background: #f8f8f8;
}
.highcharts-data-table tr:hover {
  background: #f1f7ff;
}

@media (max-width: 767.98px) {
  .profile {
    background-color: transparent;
    box-shadow: none;
  }

  .profile .dropdown-list {
    right: 0;
    left: auto;
  }

  .profile .dropdown-button {
    padding: 0;
    justify-content: center;
  }

  .profile .dropdown-button .image-user {
    width: 60px;
    height: 60px;
    border: 3px solid white;
    margin: 0;
  }

  .profile .dropdown-button .description {
    display: none;
  }

  .profile .dropdown-button .description .work {
    display: none;
  }

  .messages {
    background-color: transparent;
    box-shadow: none;
    width: 120px;
  }

  .messages .dropdown-list {
    left: auto;
    right: 0;
  }

  .messages .dropdown-button {
    padding: 0;
    justify-content: center;
  }

  .messages .dropdown-button .image-user {
    width: 60px;
    height: 60px;
    border: 3px solid white;
    margin: 0;
  }

  .messages .dropdown-button .description,
  .messages .dropdown-button .description .work{
    display: none;
  }
}
