.main-header .logo {
  display: none;
  width: 140px;
  height: 50px;
  overflow: hidden;
  margin: 30px auto 15px;
}
.main-header .logo img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.main-header .alarmContainer {
  position: relative;
}
.main-header .alarmContainer #message-count{
  color: #212529 !important;
}
.main-header .alarmContainer .icon-alarm {
  display: flex;
}
.main-header .alarmContainer .badge {
  position: absolute;
  top: 0;
}
.main-header .alarmContainer a:hover {
  text-decoration: none;
}
.main-header .avatar {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin: 25px 0 15px;
}
.main-header .avatar img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}
.mobile-header {
  display: none !important;
}
.mobile-header .menu-button {
  display: flex;
  align-items: center;
  justify-content: center;
  outline: 0;
  width: 45px;
  height: 47px;
  cursor: pointer;
  border: 0;
  border-radius: 3px;
  background: #2c3044;
}
.mobile-header .menu-button:active {
  padding: 0;
}
.mobile-header .menu-button i {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  font-size: 25px;
  color: white;
  transition: all 0.3s;
}
.mobile-header .logo {
  display: none;
  width: 140px;
  height: 50px;
  overflow: hidden;
  margin: 30px auto 15px;
}
.mobile-header .logo img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.mobile-header .avatar {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin: 25px 0 15px;
}
.mobile-header .avatar img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}

/* @include media-breakpoint-down(sm)*/
@media (max-width: 767.98px){
  .main-header {
    display: none!important;
  }

  .avatar {
    margin: 25px 20px 15px;
  }

  .mobile-header {
    position: absolute;
    top: 20px;
    width: 100%;
    display: flex !important;
    padding: 0 20px;
  }

  .mobile-header .logo {
    display: inline;
    width: auto;
    height: 50px;
    margin: auto;
    padding: 0 20px;
  }

  .mobile-header .avatar {
    margin: 25px 20px 15px;
  }
}

@media (min-width: 992px) {
  .mobile-header .menu-button {
    display: none;
  }

  .mobile-header .menu-button.collapsed-sidebar {
    width: calc(100% - 70px);
  }

}

@media (max-width: 750px) {
  .mobile-header {
    position: relative;
    margin-bottom: 35px;
    width: 100%;
    display: flex !important;
    padding: 0 20px;
  }
}

@media (max-width: 576px) {
  .mobile-header {
    position: absolute;
    top: 20px;
    width: 100%;
    display: flex !important;
    padding: 0 20px;
  }
}
