.dButton {
  border-radius: var(--round-1);
}
.dButton.round {
  border-radius: var(--round-50);
}
.dButton.fullWidth {
  width: 100%;
}

button[class^="btn-outline-"], button[class*=" btn-outline-"] {
  border: 1px solid;
}
.textBtn {
  border: none !important;
  box-shadow: none;
}
.dButton.lg{
  padding: .5rem 1rem;
  font-size: 1.015625rem;
  line-height: 1.5;
}
.dButton.md{
  padding: .3rem 1rem;
  font-size: 0.9rem;
  line-height: 1.5;
}
.dButton.sm {
  padding: 0.20rem .5rem;
  font-size: .875rem;
  line-height: 1.5;
}

.btn-primary {
  color: var(--on-prime) !important;
  background: var(--primary);
  border: 1px solid var(--primary-darken-10);
}
.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:hover,
.btn-primary.focus,
.btn-primary:focus{
  color: var(--on-prime) !important;
  background: var(--primary-darken-10);
  border: 1px solid var(--primary);
}

.btn-secondary {
  background: var(--secondary);
}
.btn-secondary:not(:disabled):not(.disabled).active,
.btn-secondary:not(:disabled):not(.disabled):active,
.btn-secondary:hover,
.btn-secondary.focus,
.btn-secondary:focus{
  background: var(--secondary);
}

.btn-danger {
  color: var(--on-prime) !important;
  background: var(--danger);
  border: 1px solid var(--danger);
}
.btn-danger:not(:disabled):not(.disabled).active,
.btn-danger:not(:disabled):not(.disabled):active,
.btn-danger:hover,
.btn-danger.focus,
.btn-danger:focus{
  color: var(--on-prime) !important;
  background: var(--danger);
  border: 1px solid var(--danger);
}


.btn-outline-primary {
  color: var(--primary);
  border: 1px solid var(--primary);
}
.btn-outline-primary:not(:disabled):not(.disabled).active,
.btn-outline-primary:not(:disabled):not(.disabled):active,
.btn-outline-primary:hover,
.btn-outline-primary.focus,
.btn-outline-primary:focus{
  color: #fff !important;
  background-color: var(--primary);
  border-color: var(--primary-darken-10);
}

.btn-outline-secondary {
  color: var(--secondary);
  border: 1px solid var(--secondary);
}
.btn-outline-secondary:not(:disabled):not(.disabled).active,
.btn-outline-secondary:not(:disabled):not(.disabled):active,
.btn-outline-secondary:hover,
.btn-outline-secondary.focus,
.btn-outline-secondary:focus{
  background-color: var(--secondary);
  border-color: var(--secondary);
}
.btn-outline-secondary {
  color: var(--secondary);
  border: 1px solid var(--secondary);
}

.btn-outline-danger:not(:disabled):not(.disabled).active,
.btn-outline-danger:not(:disabled):not(.disabled):active,
.btn-outline-danger:hover,
.btn-outline-danger.focus,
.btn-outline-danger:focus{
  background-color: var(--danger) !important;
  border-color: var(--danger) !important;
}

.btn-primary.disabled,
.btn-primary:disabled {
  color: var(--on-prime);
  background-color: var(--primary);
  border-color: var(--primary);
}
.btn.disabled, .btn:disabled {
  opacity: .65;
}
