.pagination {
  display: flex;
  align-items: center;
  direction: ltr;
}
.pagination .page-link {
  position: relative;
  display: block;
  padding: var(--pagination-padding-y) var(--pagination-padding-x);
  margin-left: var(--pagination-border-width);
  line-height: var(--pagination-line-height);
  color: var(--pagination-color);
  background-color: transparent;
  border: 0;
}
.pagination .page-link:hover {
  color: var(--color-primary);
  text-decoration: none;
  background-color: transparent;
  border-color: transparent;
}
.pagination .page-link:focus {
  outline: none;
  box-shadow: none;
}
.pagination .page-link:not(:disabled):not(.disabled) {
  cursor: pointer;
}
.pagination .page-item:first-child .page-link {
  margin-left: 0;
}
.pagination .page-item.active .page-link {
  color: var(--sidebar-background);
  background-color: transparent;
  border-color: transparent;
}
.pagination .page-item.disabled .page-link {
  color: var(--pagination-disabled-color);
  pointer-events: none;
  cursor: auto;
  background-color: transparent;
}
.pagination .icon {
  border: 1px solid gray;
  border-radius: 5px;
  background-color: white;
}
.pagination .icon a {
  display: flex;
  justify-content: center;
  align-items: center;
}
