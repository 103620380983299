.main-container .form-control {
  border-radius: 2px;
}
.main-container .card {
  border-radius: 2px;
}
.main-container .btn {
  border-radius: 5px;
}
.main-container .btn.btn-minwidth {
  min-width: 120px;
}
.main-container .custom-switch {
  height: 100%;
  display: flex;
  align-items: center;
}
.main-container .custom-switch input[type='checkbox'] {
  position: absolute;
  opacity: 0;
  visibility: hidden;
}
.main-container .custom-switch input[type='checkbox'] ~ label {
  width: 30px;
  height: 15px;
  border-radius: 50px;
  position: relative;
  transition: all ease 0.3s;
  cursor: pointer;
}
.main-container .custom-switch input[type='checkbox'] ~ label::before {
  position: absolute;
  border-radius: 50%;
  right: 0;
  transition: all ease 0.3s;
  top: 0;
  height: 100%;
  width: 15px;
  content: '';
}
.main-container .custom-switch input[type='checkbox']:checked ~ label::before {
  right: calc(100% - 15px);
}
.main-container .custom-file-upload {
  width: 100%;
}
.main-container .custom-file-upload input[type='file'] {
  visibility: hidden;
  position: absolute;
}
.main-container .custom-file-upload .custom-file-label {
  border-radius: 2px;
  position: relative;
  width: 100%;
  display: block;
  padding-left: 120px;
}
.main-container .custom-file-upload .custom-file-label::after {
  width: 100px;
  text-align: center;
  border-left: none;
  margin-right: auto;
  content: attr(data-content);
}
.main-container .custom-chk {
  position: absolute;
  visibility: hidden;
  opacity: 0;
}
.main-container .custom-chk ~ label {
  position: relative;
}
.main-container .custom-chk ~ label::before {
  position: absolute;
  right: -1.25rem;
  border-radius: 3px;
  top: 4px;
  content: '';
  width: 13px;
  height: 13px;
}
.main-container .custom-chk:checked ~ label::before {
  background-size: 9px;
}
.main-container .custom-rdo {
  position: absolute;
  visibility: hidden;
  opacity: 0;
}
.main-container .custom-rdo ~ label {
  position: relative;
}
.main-container .custom-rdo ~ label::before {
  position: absolute;
  right: -1.25rem;
  border-radius: 50%;
  top: 4px;
  content: '';
  width: 13px;
  height: 13px;
}
.main-container .custom-rdo:checked ~ label::before {
  background-size: 7px;
}
.main-container .pagination .page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: var(--color-primary);
  border-color: var(--color-primary);
}
.main-container .pagination .page-item {
  margin-top: 5px;
}
.main-container .pagination .page-item.disabled .page-link {
  color: var(--gray-600);
}
.main-container .pagination .page-item:first-child .page-link {
  border-left: none;
}
.main-container .pagination .page-item .page-link {
  color: var(--sidebar-background);
}
.main-container .pagination .page-item .page-link:focus {
  outline: 0;
  box-shadow: none;
}
.datepicker-plot-area {
  border-radius: 3px;
  font: 13px IRANSans !important;
  margin-top: 2px;
}
.datepicker-plot-area .datepicker-navigator .pwt-btn-next, .datepicker-plot-area .datepicker-navigator .pwt-btn-prev {
  color: transparent;
  border-radius: 3px;
  position: relative;
  transition: background ease 0.3s;
}
.datepicker-plot-area .datepicker-navigator .pwt-btn-next::before, .datepicker-plot-area .datepicker-navigator .pwt-btn-prev::before {
  width: 100%;
  height: 100%;
  font-family: PartFontIcon, sans-serif;
  text-align: center;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  margin: 0 auto;
  font-size: 15px;
}
.datepicker-plot-area .datepicker-navigator .pwt-btn-next::before {
  content: '\f104';
}
.datepicker-plot-area .datepicker-navigator .pwt-btn-prev::before {
  content: '\f106';
}
.datepicker-plot-area .datepicker-navigator .pwt-btn-switch {
  font-weight: 500;
  line-height: 32px;
}
.datepicker-plot-area .datepicker-day-view .month-grid-box .header .header-row-cell {
  font-weight: 500;
}
.datepicker-plot-area .datepicker-day-view .table-days td span {
  transition: all ease 0.1s;
  border-radius: 3px;
}
.datepicker-plot-area .datepicker-day-view .table-days td.selected span {
  text-shadow: none;
}
.datepicker-plot-area .datepicker-month-view .month-item {
  border-radius: 3px;
  transition: all ease 0.1s;
}
.datepicker-plot-area .datepicker-month-view .month-item.selected {
  text-shadow: none;
}
.datepicker-plot-area .datepicker-year-view .year-item {
  border-radius: 3px;
  transition: all ease 0.1s;
}
.datepicker-plot-area .datepicker-year-view .year-item.selected {
  text-shadow: none;
}
.datepicker-plot-area .toolbox .pwt-btn-calendar, .datepicker-plot-area .toolbox .pwt-btn-today {
  border-radius: 3px;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all ease 0.1s;
}
.tooltip {
  font-family: inherit;
}
a, a:link {
  color: var(--link);
}
a:hover, a:link:hover {
  color: var(--link-darken-10);
}
.custom-control-label {
  padding-right: 1.25rem;
}
.custom-control, .form-check {
  padding-left: 1.25rem !important;
  padding-right: 0 !important;
}
[type='checkbox']:not(:checked), [type='checkbox']:checked {
  position: absolute;
  opacity: 0;
  pointer-events: none;
}
[type='checkbox'] + span {
  position: relative;
  padding-right: 25px;
  cursor: pointer;
  display: inline-block;
  height: 25px;
  line-height: 25px;
  font-size: 1rem;
  user-select: none;
}
[type='checkbox'] + span:not(.lever):before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 18px;
  height: 18px;
  z-index: 0;
  border: 2px solid var(--sidebar-background);
  border-radius: 1px;
  margin-top: 3px;
  transition: 0.2s;
}
[type='checkbox']:not(.filled-in) + span:not(.lever):after {
  border: 0;
  transform: scale(0);
}
[type='checkbox']:not(:checked):disabled + span:not(.lever):before {
  border: none;
  background-color: yellow;
}
[type='checkbox'].tabbed:focus + span:not(.lever):after {
  transform: scale(1);
  border: 0;
  border-radius: 50%;
  box-shadow: 0 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: rgba(0, 0, 0, 0.1);
}
[type='checkbox']:checked + span:not(.lever):before {
  content: '';
  top: -4px;
  width: 12px;
  height: 22px;
  border-top: 2px solid transparent;
  border-left: 2px solid transparent;
  border-right: 2px solid var(--sidebar-background);
  border-bottom: 2px solid var(--sidebar-background);
  transform: rotate(40deg);
  backface-visibility: hidden;
  transform-origin: 100% 100%;
}
[type='checkbox']:checked:disabled + span:before {
  border-right: 2px solid var(--sidebar-background);
  border-bottom: 2px solid var(--sidebar-background);
}
[type='checkbox'].filled-in + span:not(.lever):after {
  border-radius: 2px;
}
[type='checkbox'].filled-in + span:not(.lever):before, [type='checkbox'].filled-in + span:not(.lever):after {
  content: '';
  right: 0;
  position: absolute;
  transition: border 0.25s, background-color 0.25s, width 0.2s 0.1s, height 0.2s 0.1s, top 0.2s 0.1s, left 0.2s 0.1s;
  z-index: 1;
}
[type='checkbox'].filled-in:not(:checked) + span:not(.lever):before {
  width: 0;
  height: 0;
  border: 3px solid transparent;
  right: 10px;
  top: 10px;
  transform: rotateZ(37deg);
  transform-origin: 100% 100%;
}
[type='checkbox'].filled-in:not(:checked) + span:not(.lever):after {
  height: 18px;
  width: 18px;
  background-color: transparent;
  border: 2px solid var(--sidebar-background);
  top: 0;
  z-index: 0;
  border-radius: 3px;
}
[type='checkbox'].filled-in:checked + span:not(.lever):before {
  top: -1px;
  right: 10px;
  width: 7px;
  height: 12px;
  border-top: 2px solid transparent;
  border-left: 2px solid transparent;
  border-right: 2px solid var(--gray-100);
  border-bottom: 2px solid var(--gray-100);
  transform: rotateZ(37deg);
  transform-origin: 100% 100%;
}
[type='checkbox'].filled-in:checked + span:not(.lever):after {
  top: 0;
  height: 18px;
  width: 18px;
  border: 2px solid var(--sidebar-background);
  background-color: var(--sidebar-background);
  z-index: 0;
  border-radius: 3px;
}
[type='checkbox'].filled-in.tabbed:focus + span:not(.lever):after {
  border-radius: 2px;
  border-color: var(--sidebar-background);
  background-color: rgba(0, 0, 0, 0.1);
}
[type='checkbox'].filled-in.tabbed:checked:focus + span:not(.lever):after {
  border-radius: 2px;
  background-color: var(--sidebar-background);
  border-color: var(--sidebar-background);
}
[type='checkbox'].filled-in:disabled:not(:checked) + span:not(.lever):before {
  background-color: transparent;
  border: 2px solid transparent;
}
[type='checkbox'].filled-in:disabled:not(:checked) + span:not(.lever):after {
  border-color: #e1e7ed;
  background-color: #e1e7ed;
}
[type='checkbox'].filled-in:disabled:checked + span:not(.lever):before {
  background-color: transparent;
}
[type='checkbox'].filled-in:disabled:checked + span:not(.lever):after {
  background-color: var(--checkbox);
  border-color: var(--checkbox);
}
[type='checkbox'].filled-in:disabled:checked + span {
  cursor: not-allowed;
  color: #999;
}
[type='checkbox'].filled-in:disabled:not(:checked) + span {
  cursor: not-allowed;
  color: #999;
}
