.en, .en-US {
  font: 14px 'Inter', sans-serif, Tahoma, verdana, Geneva !important;
  width: 100%;
}
.en .MuiTypography, .en-US .MuiTypography, .en .MuiInput-root, .en-US .MuiInput-root, .en .MuiTableSortLabel-root, .en-US .MuiTableSortLabel-root, .en .MuiTableCell-root, .en-US .MuiTableCell-root, .en .MuiMenuItem-root, .en-US .MuiMenuItem-root, .en .MuiInputBase-root, .en-US .MuiInputBase-root, .en .MuiTypography-caption, .en-US .MuiTypography-caption, .en .MuiTypography-root, .en-US .MuiTypography-root, .en .Tooltip-Tip, .en-US .Tooltip-Tip, .en .react-confirm-alert-body, .en-US .react-confirm-alert-body, .en .Toastify__toast-body, .en-US .Toastify__toast-body {
  font-family: 'Inter', IRANSans, sans-serif, Tahoma, verdana, Geneva;
}
.ar, .ar-AE, .fa, .fa-IR {
  font: 14px IRANSans, Tahoma, serif !important;
  direction: rtl;
  width: 100%;
  text-align: right;
}
.ar .MuiTypography, .ar-AE .MuiTypography, .fa .MuiTypography, .fa-IR .MuiTypography, .ar .MuiInput-root, .ar-AE .MuiInput-root, .fa .MuiInput-root, .fa-IR .MuiInput-root, .ar .MuiTableSortLabel-root, .ar-AE .MuiTableSortLabel-root, .fa .MuiTableSortLabel-root, .fa-IR .MuiTableSortLabel-root, .ar .MuiTableCell-root, .ar-AE .MuiTableCell-root, .fa .MuiTableCell-root, .fa-IR .MuiTableCell-root, .ar .MuiMenuItem-root, .ar-AE .MuiMenuItem-root, .fa .MuiMenuItem-root, .fa-IR .MuiMenuItem-root, .ar .MuiInputBase-root, .ar-AE .MuiInputBase-root, .fa .MuiInputBase-root, .fa-IR .MuiInputBase-root, .ar .MuiTypography-caption, .ar-AE .MuiTypography-caption, .fa .MuiTypography-caption, .fa-IR .MuiTypography-caption, .ar .MuiTypography-root, .ar-AE .MuiTypography-root, .fa .MuiTypography-root, .fa-IR .MuiTypography-root, .ar .Tooltip-Tip, .ar-AE .Tooltip-Tip, .fa .Tooltip-Tip, .fa-IR .Tooltip-Tip, .ar .react-confirm-alert-body, .ar-AE .react-confirm-alert-body, .fa .react-confirm-alert-body, .fa-IR .react-confirm-alert-body, .ar .Toastify__toast-body, .ar-AE .Toastify__toast-body, .fa .Toastify__toast-body, .fa-IR .Toastify__toast-body {
  font-family: IRANSans, Tahoma, serif;
  direction: rtl;
}

@media (min-width: 576px) {
  .en, .en-US {
    font-size: 14px !important;
  }
}
