.nav-tabs .nav-link.active::before,
.nav-tabs .nav-item.show .nav-link::before {
  background-color: unset;
}

.nav-tabs.primary .nav-link.active,
.nav-tabs.primary .nav-link.active:focus {
  box-shadow: inset 0 -2px 0 var(--primary-darken-10);
  color: var(--primary);
}
.nav-tabs.secondary .nav-link.active,
.nav-tabs.secondary .nav-link.active:focus {
  box-shadow: inset 0 -2px 0 var(--secondary);
  color: var(--secondary);
}
.nav-tabs.success .nav-link.active,
.nav-tabs.success .nav-link.active:focus {
  box-shadow: inset 0 -2px 0 var(--success);
  color: var(--success);
}
.nav-tabs.warning .nav-link.active,
.nav-tabs.warning .nav-link.active:focus {
  box-shadow: inset 0 -2px 0 var(--warning);
  color: var(--warning);
}
.nav-tabs.danger .nav-link.active,
.nav-tabs.danger .nav-link.active:focus {
  box-shadow: inset 0 -2px 0 var(--danger);
  color: var(--danger);
}
.nav-tabs.info .nav-link.active,
.nav-tabs.info .nav-link.active:focus {
  box-shadow: inset 0 -2px 0 var(--info);
  color: var(--info);
}
.nav-tabs.dark .nav-link.active,
.nav-tabs.dark .nav-link.active:focus {
  box-shadow: inset 0 -2px 0 var(--dark);
  color: var(--dark);
}
.nav-tabs.light .nav-link.active,
.nav-tabs.light .nav-link.active:focus {
  box-shadow: inset 0 -2px 0 var(--light);
  color: var(--light);
}

.nav-tabs .nav-link:focus:hover,
.nav-tabs .nav-link:hover {
  box-shadow: none;
  color: var(--primary);
  border-bottom: none;
}

.nav-tabs .nav-link {
  border-color: #e9ecef #e9ecef;
  border-bottom: none;
}
