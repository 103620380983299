.dInputWrapper {
  position: relative;
  min-height: 42px;
}
.dInputWrapper .dInputNumber {
  width: 100%;
  min-height: 42px;
  border-radius: var(--round-1);
}
.dInputWrapper.standard input {
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  background-color: transparent;
  border: 0;
  border-bottom: 1px solid var(--gray);
  border-radius: 0;
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  height: unset;
}
.dInputWrapper.fullWidth {
  width: 100%;
}
.dInputWrapper.outline input {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background-color: transparent;
  border: 1px solid #dadce0 !important;
  border-radius: var(--round-1) !important;
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none !important;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.dInputWrapper.outline label {
  background-color: #fff;
}
.dInputWrapper > label {
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: absolute;
  top: -4px;
  font-size: 1rem;
  color: #757575;
  cursor: text;
  -webkit-transition: color 0.2s ease-out, -webkit-transform 0.2s ease-out;
  transition: transform 0.2s ease-out, color 0.2s ease-out, -webkit-transform 0.2s ease-out;
  -webkit-transform: translateY(12px);
  transform: translateY(12px);
  -webkit-transform-origin: 0 100%;
  transform-origin: 0 100%;
  padding-right: 5px;
  padding-left: 5px;
  border-radius: var(--round-1);
}
.dInputWrapper label::after {
  position: absolute;
  top: 65px;
  display: block;
  content: "";
  opacity: 0;
  -webkit-transition: 0.2s opacity ease-out, 0.2s color ease-out;
  transition: 0.2s opacity ease-out, 0.2s color ease-out;
}
.dInputWrapper > label.active {
  font-size: 0.95rem;
  top: 0;
  -webkit-transform: translateY(-14px) scale(0.8);
  transform: translateY(-14px) scale(0.8);
}
.dInputWrapper.lg {
  width: 100%;
}
.dInputWrapper.lg input {
  padding: 1rem 0.3rem;
}
.dInputWrapper.md input {
  padding: 0.5rem 0.3rem;
  height: unset;
}
.dInputWrapper.sm input {
  padding: 0.3rem 0.3rem;
  height: unset;
}
.dInputWrapper.sm > label {
  font-size: 13px;
}
.dInputWrapper.sm > label.active {
  top: 5px;
}
.rtl .dInputWrapper > label {
  right: 5px;
}
.ltr .dInputWrapper > label {
  left: 5px;
}
.dInputWrapper.standard textarea {
  border: none;
  border-radius: 0;
}
.dInputWrapper .invalid-feedback {
  display: block;
}
.dInputWrapper input:disabled, .dInputWrapper input[readonly] {
  background-color: #e9ecef !important;
  opacity: 1;
}
.disabledInput > label {
  background-color: #e9ecef !important;
}
