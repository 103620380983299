form label {
  display: block;
}
.table {
  background-color: #fff;
}
.table header {
  display: flex;
  border-bottom: 1px solid #9e9e9e;
  padding: 8px 0;
}
.table header .col {
  flex: 1;
  padding: 4px;
}
.table header .col2 {
  flex: 2;
  padding: 4px;
}
.table .tblRow {
  display: flex;
}
.table .tblRow .col {
  flex: 1;
  padding: 4px;
}
.table .tblRow .col2 {
  flex: 2;
  padding: 4px;
}

button:focus {
  outline: 0 auto transparent;
}
.error-alert {
  width: 100%;
  font-size: 80%;
  color: #dc3545;
}
.main {
  flex-wrap: wrap;
  width: 100%;
  min-height: 100vh;
  position: relative;
  background-color: var(--main-background);
  display: flex;
  flex-direction: column;
  height: 100%;
}
.main .main-footer {
  text-align: center;
  flex: 1 0 auto;
  min-height: 3rem;
  padding: 10px;
  margin: 0;
  width: 100%;
  color: var(--footer-text-color);
  background-color: var(--footer-background);
  border-top: var(--footer-border-top);
}
.main .main-footer a {
  color: var(--footer-link-color);
}
.main .main-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-right: auto;
  padding: 30px 35px 10px 35px;
  background-color: var(--content-background);
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
  height: 100%;
}
.main .main-content .contentWrapper {
  margin: 1.5rem;
  min-height: 70vh;
}
.main .main-content .titleBar {
  margin-top: 30px;
}
.main .main-content .titleBar h6 {
  font-size: 18px;
  margin-top: 8px;
}
.main .main-content .emptyState {
  text-align: center;
  color:var(--silver);
  padding: 40px 20px;
}
.main .main-content .emptyState img {
  width: 76px;
  margin-bottom: 20px;
}
.main .main-content input {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
}
.main .main-content input::placeholder {
  color: var(--input-placeholder-color);
  opacity: 1;
  /* Firefox */
}
.main .main-content input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: var(--input-placeholder-color);
}
.main .main-content input::-ms-input-placeholder {
  /* Microsoft Edge */
  color: var(--input-placeholder-color);
}
.main .main-content input.price {
  box-shadow: none;
  border-radius: 0;
  border: 0;
  border-bottom: 1px solid var(--gray);
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  margin-bottom: 0;
  display: block;
  height: calc(2.25rem + 4px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
}
.main .main-content .main-wrapper {
  flex: 1 1 100%;
  margin: 4.5rem 0 60px 0;
}
.main .main-content .main-wrapper .photoGallery div {
  border-bottom: 1px dotted #f5f5f5;
  padding: 5px 0;
}
.main .main-content .main-wrapper .photoGallery div img {
  width: 64px;
}
.main .main-content .main-wrapper .photoGallery div .icon-bin {
  cursor: pointer;
}
.main .main-content .main-wrapper .css-1pahdxg-control {
  border-radius: 0;
  border: 0;
  border-bottom: 1px solid var(--gray);
  box-shadow: none;
}
.main .main-content .main-wrapper .css-1pahdxg-control:hover {
  border: 0;
  border-bottom: 1px solid var(--secondary);
  border-radius: 0;
}
.main .main-content .main-wrapper .css-1pahdxg-control .css-1okebmr-indicatorSeparator {
  background-color: transparent;
}
.main .main-content .grid-view {
  margin-top: 5px;
}
.main .main-content .grid-view .MuiPaper-root {
  background: transparent;
}
.main .main-content .grid-view .MuiPaper-root > div:nth-child(2n + 2) {
  border-radius: var(--round-1);
  background-color: #fff;
  padding: 4px;
  border: 1px solid var(--border);
}
.main .main-content .grid-view .MuiPaper-root > div:nth-child(2n + 2) .MuiTable-root .MuiTableHead-root tr {
  border-radius: 10px;
}
.main .main-content .grid-view .MuiPaper-root > div:nth-child(2n + 2) .MuiTable-root .MuiTableHead-root tr .MuiTableCell-root {
  z-index: 0;
  background-color: #f7f8f9 !important;
  padding: 5px;
}
.main .main-content .grid-view .MuiPaper-root .MuiTable-root .MuiTableCell-root {
  border: 0;
}
.main .main-content .grid-view .MuiPaper-root .MuiTable-root .MuiTableCell-root .icons {
  display: flex;
  justify-content: flex-end;
  flex-direction: row-reverse;
  margin-top: 10px;
}
.main .main-content .grid-view .MuiPaper-root .MuiTable-root .MuiTableCell-root .icons a {
  text-decoration: none;
  margin: 0 7px;
}
.main .main-content .grid-view .MuiPaper-root .MuiTable-root .MuiTableCell-root .icons i {
  cursor: pointer;
}
.main .main-content .grid-view .MuiPaper-root .MuiTable-root .MuiTableCell-root .icons i:before {
  color: var(--gray-500);
}
.main .main-content .grid-view .MuiPaper-root .MuiFormControl-root {
  background: #fff;
  border-radius: var(--round-1);
  border: 1px solid var(--border);
}
.main .main-content .grid-view .MuiPaper-root .MuiFormControl-root .MuiInput-underline:before {
  border-bottom: 0;
}
.main .main-content .grid-view .MuiPaper-root .MuiFormControl-root .MuiInput-underline:after {
  border-bottom: 0;
}
.main .main-content .grid-view .MuiPaper-root .MuiFormControl-root .MuiInput-underline:hover:not(.Mui-disabled):before {
  border-bottom: 0;
}
.main .main-content .grid-view .MuiPaper-root .MuiFormControl-root input {
  box-shadow: unset;
}
.main .main-content .grid-view .MuiPaper-root .MuiToolbar-root {
  padding: 2px;
}
.main .main-content .grid-view .MuiPaper-root .MuiToolbar-root h6 {
  font-weight: bold;
  font-size: 14px;
}
.main .main-content .grid-view .MuiPaper-root .inline-toolbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.main .main-content .grid-view .MuiPaper-root .inline-toolbar .custom-toolbar {
  display: flex;
  align-items: baseline;
  flex-basis: 60%;
  justify-content: space-between;
}
.main .main-content .grid-view .MuiPaper-root .inline-toolbar .custom-toolbar button i {
  display: none;
}
.main .main-content .grid-view .MuiPaper-root .inline-toolbar .custom-toolbar button span {
  display: inline-block;
}
.main .main-content .grid-view .MuiPaper-root .inline-toolbar .MuiToolbar-root {
  display: inline-flex;
}
.main .main-content .grid-view .MuiPaper-root .inline-toolbar .MuiToolbar-gutters {
  padding: 0;
}
.main .main-content .show-panel {
  background-color: white;
  margin: 0;
  border-radius: var(--round-2);
  border: 1px solid var(--border);
  min-height: 65vh;
}
.main .main-content .show-panel > h6 {
  font-size: 1.2rem;
  font-weight: bold;
  margin: 20px 0;
}
.main .main-content .show-panel hr {
  border: 0;
  border-bottom: 1px dashed var(--silver);
  margin: 32px 0;
}
.main .main-content .show-panel hr.white {
  border-bottom: 1px solid white;
  margin: 24px 0;
}
.main .main-content .show-widget {
  padding: 20px;
  margin: 5px;
  border-radius: 15px;
}
.main .main-content .show-widget.smallToolbar .MuiToolbar-gutters {
  min-height: auto;
}
.main .main-content .show-widget.smallToolbar .MuiToolbar-gutters h6 {
  font-weight: bold;
  font-size: 14px;
}
.main .main-content .show-widget .MuiPaper-root > div:nth-child(2n + 2) {
  padding: 20px 0;
}
.main .main-content .hideToolbar .MuiToolbar-gutters {
  display: none;
}
.main .main-content .MuiPaper-elevation2 {
  box-shadow: none;
}
.main .main-content .MuiPaper-elevation2 .MuiTypography-h6 {
  font-size: 14px;
}
.main-container [class*='css-'] [class$='-control'] {
  border-radius: 0;
  border: 0;
  border-bottom: 1px solid var(--gray);
  box-shadow: none;
}
.main-container [class*='css-'] [class$='-control']:hover {
  border: 0;
  border-bottom: 1px solid var(--secondary);
  border-radius: 0;
}
.main-container [class*='css-'] [class$='-control'] [class$='-indicatorSeparator'] {
  background-color: transparent;
}
.main-container input.form-control {
  box-shadow: none;
  border-radius: 0;
}
.main-header {
  position: absolute;
  left: 1rem;
}
.main-header .main-header-flex {
  display: flex;
}
.ltr .main-header {
  left: unset;
  right: 1rem;
}
.button-container span {
  margin: 0 5px;
  box-shadow: unset;
}
.button-container button {
  margin: 0;
}
.page-loader {
  display: flex;
  justify-content: center;
  margin-top: 100px;
  top: 0;
  bottom: 0;
  align-items: center;
}
.loading-wrapper {
  width: 100%;
  background-color: var(--content-background);
}
.loading-wrapper em {
  position: fixed;
  top: 50%;
  left: 50%;
}
.loading {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
}
.loading::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}
@keyframes loading-dots-animation {
  0% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(0, 10px);
  }
  100% {
    transform: translate(0, 0);
  }
}
.loading .loading-dots {
  display: flex;
  position: relative;
}
.loading .loading-dots .dot {
  width: 10px;
  height: 10px;
  margin: 0 4px;
  border-radius: 10px;
  background-color: #e8f4fb;
}
.loading .loading-dots .dot:nth-last-child(2) {
  animation: loading-dots-animation 0.6s 0.1s linear infinite;
}
.loading .loading-dots .dot:nth-last-child(3) {
  animation: loading-dots-animation 0.6s 0.2s linear infinite;
}
.loading .loading-dots .dot:nth-last-child(4) {
  animation: loading-dots-animation 0.6s 0.3s linear infinite;
}
.loading .loading-dots p {
  display: inline-block;
  white-space: nowrap;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 30px);
  color: var(--gray-200);
}
.relative-pos {
  position: relative !important;
}
.input-group > select.form-control {
  border-radius: 0;
  border: 0;
  border-bottom: 1px solid var(--gray);
  box-shadow: none;
}
.cursor-pointer {
  cursor: pointer;
}
.fixed-pos {
  position: fixed;
}
.border-box {
  border: 1px solid #e5e5e5;
  border-radius: var(--round-2);
  padding: 10px;
}
.alert {
  border-radius: var(--round-1) !important;
}
.alert a:hover {
  text-decoration: none;
}
.Toastify__toast.Toastify__toast--warning {
  background-color: var(--warning);
}
.Toastify__toast.Toastify__toast--info {
  background: var(--info);
}
.Toastify__toast.Toastify__toast--success {
  background: var(--success);
}
.Toastify__toast.Toastify__toast--error {
  background-color: var(--danger);
}
.Toastify .Toastify__toast-body {
  text-align: center;
}
.link-button-transparent {
  display: block;
  cursor: pointer;
  border: none;
  width: 100%;
  padding: 0;
  background-color: transparent;
}
.link-button-transparent div:first-child {
  border: 1px solid var(--border);
}
.link-button-transparent.active div:first-child {
  border: 2px solid var(--primary);
}
tr.MuiTableRow-root {
  display: none;
}
tr.MuiTableRow-root.MuiTableRow-head, tr.MuiTableRow-root.MuiTableRow-footer, .MuiTableRow-root[level] {
  display: table-row;
}
.MuiTableCell-root {
  padding: 8px !important;
}
.MuiIconButton-root.Mui-disabled {
  visibility: hidden;
}
.MuiLinearProgress-colorPrimary {
  background-color: transparent !important;
  margin: -3px 10px 0;
  border-radius: 2px;
}
.MuiLinearProgress-barColorPrimary {
  background-color: var(--primary) !important;
}
.unread {
  font-weight: 600;
}
.messageDate {
  font-size: 12px;
  text-align: right;
}
.sidebar-cover {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 10;
  display: none;
  cursor: pointer;
}
.collapsed-sidebar .sidebar-cover {
  display: block;
}
[class^='modal---modal-overlay'] .hoursModal[class*='modal---modal-content'] {
  max-width: max-content !important;
}
[class^='modal---modal-overlay'] .hoursModal[class*='modal---modal-content'] [class*='modal---modal-body---'] {
  padding: 16px 0 !important;
}
.tether-element {
  z-index: 9999;
}
.bg-dark {
  background-color: var(--secondary);
}
.bg-gold {
  background-color: #c09d61;
}
.mobilePreview {
  display: none;
}
.modalFooter .modalMore {
  margin-right: unset !important;
}
.announcementBtnGroup div:not(.spinner-border) {
  padding: 24px 15px;
}
.text-help {
  font-size: 12px;
  color: #888;
}
#formAwards .button-container {
  width: 100%;
  justify-content: flex-end;
}
#productsForm .button-container {
  width: 100%;
  justify-content: flex-end;
}
/*#productsForm .productRow:hover {*/
/*  background: var(--primary-light-40);*/
/*}*/
#productsForm .productRow:hover .form-control {
  background: transparent;
}
#productsForm .productRow:hover .price {
  background: transparent;
}
#formDefinitions .input-group {
  align-items: baseline;
}
#formDefinitions .input-group input.form-control {
  min-width: 40px;
  max-width: 60px;
  padding: 5px;
  margin: 0 5px;
  line-height: 1;
  height: auto;
}
#chartPlaceholder {
  width: 100%;
  height: 350px;
  text-align: center;
}
#chartPlaceholder div {
  margin-bottom: 32px;
}
#chartPlaceholder div.description {
  color: var(--gray-500);
}
#chartPlaceholder img {
  height: 250px;
  margin-bottom: -75px;
}
table.productList {
  width: 100%;
}
table.productList th {
  padding: 5px;
  text-align: center;
}
table.productList td {
  padding: 7px;
}
table.productList .invoiceItems td {
  border-left: 1px dotted #dedede;
  text-align: center;
}
table.productList .invoiceItems td:first-child {
  border-right: 1px dotted #dedede;
}
table.productList .topBorder td {
  border-top: 1px dotted #dedede;
}
table.productList .bottomBorder th {
  border-bottom: 1px dotted #dedede;
}
.bold {
  font-weight: bold;
}
.qtyCol {
  width: 25%;
}
.ltrSign {
  direction: ltr;
}
.maxWidth {
  display: inline;
  min-width: 250px;
}
.Width50 {
  width: 50px;
}
.Width100 {
  width: 100px;
}
.noBorder {
  border: 0;
  border-bottom: 0;
}
@media print {
  body * {
    visibility: hidden;
  }
  body .d-print, body .d-print * {
    visibility: visible;
  }
  body .d-print-none {
    display: none;
  }
  body .info-page__box-left .box-end {
    visibility: visible;
    position: absolute;
    top: -70vh;
    height: auto;
    min-height: auto;
    margin: 0;
    left: 15%;
    width: 100%;
    border: none;
  }
  body .info-page__box-left .box-end .no-print {
    visibility: hidden;
  }
  body .info-page__box-left .box-end .result {
    border: 1px solid #888;
    margin: auto;
  }
  body .info-page__box-left .box-end .result * {
    visibility: visible;
  }
  body .info-page__box-left .box-end .result * .no-print {
    visibility: hidden;
  }
  body .info-page__box-left .box-end button {
    visibility: hidden;
  }
  body .info-page__box-left .box-end button * {
    visibility: hidden;
  }
  body .info-page__box-left .box-end .div-end {
    position: relative;
  }
}
img.round {
  border-radius: var(--round-1);
}
.cursor-pointer {
  cursor: pointer;
}
.content-margin {
  margin-top: var(--content-margin);
}
.full-width {
  width: 100%;
}
.equal {
  display: flex;
  display: -webkit-flex;
  flex-wrap: wrap;
}
@media (min-width: 768px) {
  .row.equal {
    display: flex;
    flex-wrap: wrap;
  }
}
.directionLtr {
  direction: ltr;
}
.alert-primary {
  background: #001e00 !important;
}
.MuiFormControl-root {
  background: #fff;
  border-radius: var(--round-1);
  border: 1px solid var(--border);
}
.MuiFormControl-root .MuiInput-underline:before {
  border-bottom: 0;
}
.MuiFormControl-root .MuiInput-underline:after {
  border-bottom: 0;
}
.MuiFormControl-root .MuiInput-underline:hover:not(.Mui-disabled):before {
  border-bottom: 0;
}
.MuiFormControl-root input {
  box-shadow: unset;
}
.MuiToolbar-root {
  padding: 2px;
}
.mt-2r {
  margin-top: 2rem;
}
.align-center {
  text-align: center;
}
.rtl .margin-50 {
  margin-right: 50%;
}
.ltr .margin-50 {
  margin-left: 50%;
}
.text-info {
  color: var(--primary) !important;
}
a.text-info:hover, a.text-info:focus {
  color: #000a00 !important;
}
.helpModal .imageWrapper {
  width: 100%;
}
.helpModal .imageWrapper img {
  width: 100%;
}
.content-nx {
  margin-right: -1rem;
  margin-left: -1rem;
}
.rtl .text-end {
  text-align: left !important;
}
.ltr .text-end {
  text-align: right !important;
}
.btn-outline-primary:hover {
  background-color: var(--white);
  color: var(--primary) !important;
}

.btn-primary {
  border: 0 !important;
}
.btn-primary:hover, .btn-primary:active, .btn-primary:focus {
  border: 0 !important;
  box-shadow: unset !important;
}
.btn-secondary {
  border: 0 !important;
}
.btn-secondary:hover, .btn-secondary:active, .btn-secondary:focus {
  border: 0 !important;
}
.btn-success {
  border: 0 !important;
}
.btn-success:hover, .btn-success:active, .btn-success:focus {
  border: 0 !important;
}
.form-control:focus {
  border-color: #dadce0 !important;
  box-shadow: unset !important;
}
.btn-outline-primary, .btn-outline-secondary {
  border: 0 !important;
}
.btn-outline-primary:hover, .btn-outline-secondary:hover, .btn-outline-primary:active, .btn-outline-secondary:active, .btn-outline-primary:focus, .btn-outline-secondary:focus {
  border: 0 !important;
  box-shadow: unset !important;
}
.text-help {
  font-size: 12px;
  color: #888;
  margin: 1rem;
}
.rw-widget-container {
  border-radius: var(--round-1) !important;
}
.main-content .grid-view .custom-toolbar button i {
  display: none;
}
.main-content .grid-view .custom-toolbar button span {
  display: inline-block;
}
.mobile-header {
  display: none !important;
}

@media (max-width: 992px) {
  .hide-on-large {
    display: none !important;
  }
}

/*@include media-breakpoint-down(sm)*/
@media (max-width: 768px) {
  .hide-on-medium, .hide-on-mobile {
    display: none !important;
  }

  .main .main-content input.price {
    font-size: 12px;
    padding: 4px 1px;
  }

  .main-content .grid-view .MuiTable-root h5 {
    font-size: 12px;
    font-weight: bold;
  }

  .main-content .grid-view .MuiToolbar-root h6 {
    font-size: 13px;
  }

  .main-content .grid-view .inline-toolbar {
    flex-direction: column;
  }

  .main-content .grid-view .custom-toolbar {
    flex-basis: 100%;
  }

  .main-content .grid-view .custom-toolbar button i{
    display: inline-block;
  }

  .main-content .grid-view .custom-toolbar button span{
    display: none;
  }

  [class^='modal---modal-overlay'] .hoursModal[class*='modal---modal-content'] [class*='modal---modal-body---'] {
    padding: 16px 5px !important;
  }

  .btn {
    font-size: 13px !important;
    padding: 8px 10px !important;
  }

  .hidden-sm {
    display: none;
  }

  .mobilePreview {
    display: inline-block;
  }

  .modalFooter .btn {
    min-width: auto;
  }

  .modalMore {
    font-size: 11px;
    flex-basis: 40%;
  }

  .logoContainer .col-md-2 {
    width: 19.9%;
  }

  .logoContainer .col-md-10 {
    width: 79.9%;
  }
  .MuiTablePagination-root {
    padding: 8px 13px !important;
  }

  .announcementBtnGroup {
    flex-wrap: wrap;
  }

  .announcementBtnGroup button{
    width: 40%;
    font-size: 10px !important;
  }

  .announcementBtnGroup div{
    padding: 18px;
    font-size: 10px;
  }

  .flexSmColumn {
    flex-direction: column;
  }

  .announcementPreviewImg {
    width: 100%;
  }

  .order-xs-last {
    order: 7;
  }
}

@media (max-width: 576px) {
  .hide-on-small {
    display: none !important;
  }

  .main-content {
    margin-top: 100px;
    padding: 10px 20px 10px 20px;
    border-radius: 25px 25px 0 0;
  }

  .main-content .d-block-mobile {
    display: block !important;
    margin-bottom: 5px;
  }
}
@media (max-width: 992px) {
  .no-top-margin-on-large {
    margin-top: 0 !important;
  }
}
@media screen and (min-width: 3840px) {
  .main-content {
    padding: 30px 80px 20px 80px;
  }
}

@media (max-width: 320px) {
  div[class^="MTablePaginationInner-root-"], div[class*=" MTablePaginationInner-root-"] {
    display: block;
  }
}

/*@include breakpoint(medium)*/
@media screen and (min-width: 768px) {
  .main .main-content {
    width: calc(100% - var(--width-sidebar));
    min-height: 95vh;
  }

  .main .main-content .grid-view .MuiPaper-root>div:nth-child(2n+2) {
    padding: 20px;
  }

  .main .main-content .show-panel {
    /*margin: 1rem;*/
    margin: 20px 1rem 1rem;
  }

  .content-nMargin {
    margin-top: 1rem;
  }

  .main .main-content .contentWrapper {
    margin-top: 5rem;
    margin-bottom: 1rem;
  }
}


/*@include breakpoint(large)*/
@media screen and (min-width: 992px) {
  .fixed-pos {
    position: unset;
  }

  .content-nMargin {
    margin-top: -5rem;
    margin-bottom: -3rem;
  }

  .box-left-wrapper {
    margin-top: 5rem;
    margin-bottom: 3rem;
  }
}

