@import "./Accordion/AccordionStyle.css";
@import "./Button/ButtonStyle.css";
@import "./Input/InputStyle.css";
@import "./Checkbox/CheckboxStyle.css";
@import "./Radio/RadioStyle.css";
@import "./Dropdown/DropdownStyle.css";
@import "./Tab/TabStyle.css";
@import "./Spinner/SpinnerStyle.css";
@import "./Select/SelectStyle.css";
@import "./Modal/ModalStyle.css";
@import "./Switch/SwitchStyle.css";
@import "./Badge/BadgeStyle.css";
@import "./FormatText/FormatText.css";

body, button, input {
  letter-spacing: inherit;
}
